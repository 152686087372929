import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api'; 

export const ForgetPasswords = () => { 
    const [userName, setUserName] = useState("");
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setError('New Password and Confirm Password do not match');
            return;
        }

        setError('');

        try {
            const response = await api.post('/auth/agent/resetpassword', {
                email: userName,
                password: newPassword,
            });
            setSuccessMessage('Your password has been reset successfully.');
            setTimeout(() => {
                navigate('/');
            }, 3000);
        } catch (error) {
            console.error('Error occurred during password reset:', error);
            if (error.response && error.response.status === 422) {
                setErrors({ login: 'Wrong credentials. Please try again.' });
            } else {
                setErrors({ generic: 'An error occurred. Please try again later.' });
            }
        }
    };

    return (
        <section className="admin-login">
            <div className="container">
                <div className="adminform-inner">
                    <div className="login-logo">
                        <a href="#"><img src="../images/logo.png" alt="logo-img" /></a>
                    </div>
                    <div className="login-form mt-5">
                        <h3>Reset Your Password</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mt-5">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="admin-username"
                                    placeholder="User Name"
                                    value={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                />
                            </div>
                            <div className="form-group mt-2">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="admin-new-password"
                                    placeholder="New Password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                            </div>
                            <div className="form-group mt-2">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="admin-confirm-password"
                                    placeholder="Confirm Password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </div>
                            {error && <div className="text-danger my-3">{error}</div>}
                            {successMessage && <div className="text-success my-3">{successMessage}</div>}
                            <button type="submit" className="btn btn-primary w-100 py-3">Confirm</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};
