import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import { Login } from './Auth/Login';
import { ForgetPasswords } from './Auth/ForgetPasswords'; 
import { AgentCarInspection } from './Admin/AgentCarInspection';
import { AgentCarCalender } from './Admin/AgentCarCalender';
import { AgentViewPostedInspection } from './Admin/AgentViewPostedInspection';
import { AgentCarDetailsPopup } from './Admin/AgentCarDetailsPopup';
import { AgentViewinspection } from './Admin/AgentViewinspection';
import { AgentCarView } from './Admin/AgentCarView';
import { AgentNav } from './Admin/AgentNav';
import { Bos } from './Admin/Bos';
import { BosCarDetails } from './Admin/BosCarDetails';
import { SellerBos }  from './Admin/SellerBos';
import { DealerBos } from './Admin/DealerBos';
import { UploadVehicleDetails } from './Admin/UploadVehicleDetails';
import { FutureCarAuctions } from './Admin/FutureCarAuctions';
import { CarDetails } from './Admin/CarDetails';
import { LiveAuctions } from './Admin/LiveAuctions';
import { LiveAuctionDetails } from './Admin/LiveAuctionDetails';
import { BidHistory } from './Admin/BidHistory';
import { SoldCarList } from './Admin/SoldCarList';
import { SoldCarDetails } from './Admin/SoldCarDetails';

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && window.location.protocol !== 'https:') {
      window.location.href = `https://${window.location.host}${window.location.pathname}`;
    }
  }, []);
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/agent-car-inspection" element={<AgentCarInspection />} />
          <Route path="/agent-car-calender" element={<AgentCarCalender />} />
          <Route path="/agent-view-posted-inspection/:Vehicle_Id" element={<AgentViewPostedInspection />} />
          <Route path="/agent-car-details-popup/:Vehicle_Id" element={<AgentCarDetailsPopup />} />
          <Route path="/agent-view-inspection" element={<AgentViewinspection />} />
          <Route path="/agent-car-view" element={<AgentCarView />} />
          <Route path="/AgentNav" element={<AgentNav />} />
          <Route path="/bos" element={<Bos />} />
          <Route path="/bos-car-details/:Vehicle_Id" element={<BosCarDetails />} />
          <Route path="/seller-bos/:sellerid/:Vehicle_Id" element={<SellerBos />} />
          <Route path="/dealer-bos/:Vehicle_Id" element={<DealerBos />} />
          <Route path="/uploadvehicledetails/:sellerid" element={<UploadVehicleDetails />} />
          <Route path="/forget-agent-password" element={<ForgetPasswords />} />
          <Route path="/future-car-auctions" element={<FutureCarAuctions />} />
          <Route path="/car-details/:Vehicle_Id" element={<CarDetails />} />
          <Route path="/live-auctions" element={<LiveAuctions />} />
          <Route path="/live-auction-details/:Vehicle_Id" element={<LiveAuctionDetails />} />
          <Route path="/bid-history/:Vehicle_Id" element={<BidHistory />} />
          <Route path="/sold-cars" element={<SoldCarList />} />
          <Route path="/sold-car-details/:Vehicle_Id" element={<SoldCarDetails />} />
        </Routes>
      </div>
    </Router>

  );
}

export default App;
